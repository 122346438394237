.c-user-feedback--input-element {
  vertical-align: bottom;
}

.c-user-feedback--text-input {
  width: 100%;
}

.c-user-feedback--open-button {
  &,
  &:visited {
    border-color: $bitstyles-button-icon-background-color;
    background: $bitstyles-button-icon-background-color;
    color: $bitstyles-button-icon-color;
  }
}
