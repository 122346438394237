.o-input--checkbox {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: $bitstyles-input-padding * 0.5;
  transition:
    color $bitstyles-transition-duration * 0.5 $bitstyles-transition-easing,
    background-color $bitstyles-transition-duration * 0.5 $bitstyles-transition-easing,
    border $bitstyles-transition-duration * 0.5 $bitstyles-transition-easing;
  border: $bitstyles-checkbox-border;
  border-radius: $bitstyles-checkbox-border-radius;
  background-color: $bitstyles-checkbox-background-color;
  color: $bitstyles-checkbox-color;
  vertical-align: middle;
  cursor: pointer;
  appearance: none;

  &:hover,
  &:focus {
    border: $bitstyles-checkbox-border-hover;
    outline: none;
    background-color: $bitstyles-checkbox-background-color-hover;
    color: $bitstyles-checkbox-color-hover;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(0.7);
    transition:
      opacity ($bitstyles-transition-duration * 0.5) $bitstyles-transition-duration $bitstyles-transition-easing,
      transform ($bitstyles-transition-duration * 0.5) $bitstyles-transition-duration $bitstyles-transition-easing;
    opacity: 0;
    background: $bitstyles-checkbox-background-image-checked 0 0 no-repeat;
    background-size: 0.85em;
  }

  &:checked {
    border: $bitstyles-checkbox-border-checked;
    background-color: $bitstyles-checkbox-background-color-checked;
    color: $bitstyles-checkbox-color-checked;

    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }

  &[disabled] {
    border: $bitstyles-checkbox-border-disabled;
    background-color: $bitstyles-checkbox-background-color-disabled;
    color: $bitstyles-checkbox-color-disabled;

    &:checked {
      border: $bitstyles-checkbox-border-disabled-checked;
      background-color: $bitstyles-checkbox-background-color-disabled-checked;
      color: $bitstyles-checkbox-color-disabled-checked;
    }
  }
}
