$test-progress-height: 3rem;

.c-test-progress__inner {
  position: relative;
  height: $test-progress-height;
  overflow: hidden;
  border-radius: $bitstyles-border-radius-round;
  background: palette('grey');
}

.c-test-progress__progress {
  @include absolute-cover;

  z-index: 1;
  right: auto;
  box-sizing: border-box;
  padding-left: $test-progress-height * 0.5;
  overflow: hidden;
  transition: background $bitstyles-transition-duration $bitstyles-transition-easing;
  border-radius: 0 $bitstyles-border-radius-round $bitstyles-border-radius-round 0;
  background: var(--subtest-brand-color);
  box-shadow: 2px 0 20px rgba(palette('background-secondary'), 0.2);
}

.c-test-progress__badge {
  position: relative;
  z-index: 2;
  width: $test-progress-height;
  height: $test-progress-height;
  transition: background $bitstyles-transition-duration $bitstyles-transition-easing;
}

.c-test-progress__label {
  @include vertical-center;
  @include truncate-with-ellipsis;
  @include text-shadow($bitstyles-color-white);

  left: 3.5rem;
  transition: color $bitstyles-transition-duration $bitstyles-transition-easing;
  color: $bitstyles-color-white;

  .c-test-progress__progress & {
    color: palette('background-secondary');
  }
}

.c-test-progress__progress--correct {
  --subtest-brand-color: #{palette('question-correct')};
  --subtest-brand-color-dark: #{palette('question-correct')};
}

.c-test-progress__progress--incorrect {
  --subtest-brand-color: #{palette('question-incorrect')};
  --subtest-brand-color-dark: #{palette('question-incorrect')};
}

.c-test-progress {
  &:hover,
  &:focus {
    .c-test-progress__badge,
    .c-test-progress__progress {
      background: var(--subtest-brand-color-dark);
    }

    .c-test-progress__label {
      color: var(--subtest-brand-color-dark);
    }

    .c-test-progress__progress {
      /* stylelint-disable max-nesting-depth */
      .c-test-progress__label {
        color: palette('background-secondary');
      }
      /* stylelint-enable max-nesting-depth */
    }
  }
}
