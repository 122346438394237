.t-no-border {
  border: 0;
}

.t-border--top {
  border-top: 1px solid palette('pale-gray');
}

.t-border--dark-grey {
  border: 1px solid palette('grey', 'dark');
}
