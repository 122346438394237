$badge-size: 3em;

.o-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: $badge-size;
  min-height: $badge-size;
  transition: color $bitstyles-transition-duration $bitstyles-transition-easing,
    border-color $bitstyles-transition-duration $bitstyles-transition-easing;
  border-radius: $bitstyles-border-radius-round;
  background-color: palette('primary');
  color: palette('background-secondary');
  font-weight: $bitstyles-font-weight-normal;
  text-align: center;
  vertical-align: middle;
}

.o-badge--ghost {
  border: 1px solid var(--brand-color-primary);
  background-color: palette('background-secondary');
  color: var(--brand-color-primary);

  .o-button[disabled] &,
  .o-button[disabled]:hover &,
  .o-button[disabled]:focus & {
    border-color: palette('grey', 'dark');
    background-color: transparent;
    color: palette('grey', 'dark');
  }
}

.o-badge__label {
  @include absolute-center;

  display: inline-block;
  line-height: 1;
  filter: drop-shadow(0 0 0.15em rgba($bitstyles-color-black, 0.1));
}

.o-badge--subtest {
  position: relative;
  background: var(--subtest-brand-color);
  color: inherit;
}

.o-badge--small {
  min-width: 1.4em;
  min-height: 1.4em;
  font-size: px-to-rem(12px);

  .o-badge__label {
    padding: 0;
  }
}

.o-badge--bordered {
  border: 1px solid palette('background-secondary') !important; // stylelint-disable-line declaration-no-important
}

.o-badge--category {
  border: 0;
  background: var(--category-color, var(--subtest-brand-color));
}

.o-badge--medical-test {
  background: var(--brand-color-primary);
}

.o-badge--book {
  background: var(--book-color);
}

.o-badge--event {
  background: var(--event-color);
}
