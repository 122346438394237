.o-link--test-color {
  &,
  &:visited {
    color: var(--button-background);
  }

  &:hover,
  &:focus {
    color: var(--button-dark);
  }
}
