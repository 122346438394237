$card-padding: spacing('base');
$card-padding-medium: spacing('base') * 1.5;
$card-padding-large: spacing('large');
$card-border-radius: spacing('base');
$card-header-border-width: 1px;

/* stylelint-disable selector-max-type */
.c-card {
  margin-bottom: spacing('base');
  padding: $card-padding;
  overflow: hidden;
  border-radius: $card-border-radius;
  background-color: palette('background-secondary');
  box-shadow: 0 0 spacing('base') rgba($bitstyles-color-black, 0.04);
}

.c-card--fullheight {
  height: 100%;
}

.c-card--no-background {
  background-color: transparent;
  box-shadow: none;
}

.c-card--highlight {
  background-color: palette('turquoise', 'light');
}

.c-card__header {
  @include arrow-base(1rem, $bitstyles-color-white, 0, $bitstyles-color-white);
  @include arrow-top(1rem, $bitstyles-color-white, 0, $bitstyles-color-white);

  position: relative;
  margin: (-$card-padding) (-$card-padding) ($card-padding);
  padding: spacing('base') $card-padding;
  border: $card-header-border-width solid var(--brand-color-primary);
  border-radius: $card-border-radius $card-border-radius 0 0;
  background: var(--brand-color-primary);
  color: $bitstyles-color-white;
  text-align: center;

  &::before,
  &::after {
    bottom: -$card-header-border-width;
  }
}

.c-card__header--ghost {
  @include arrow-base(1rem, $bitstyles-color-white, 1px, $bitstyles-color-white);
  @include arrow-top(1rem, $bitstyles-color-white, 1px, $bitstyles-color-white);

  background: transparent;
  color: var(--brand-color-primary);

  &::before,
  &::after {
    bottom: -$card-header-border-width;
  }

  &::before {
    border-bottom-color: var(--brand-color-primary);
  }
}

.c-card__header--disabled {
  border-color: palette('grey', 'dark');
  color: palette('grey', 'dark');

  &::before {
    border-bottom-color: palette('grey', 'dark');
  }
}

.c-card__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $card-padding (-$card-padding) (-$card-padding) (-$card-padding);
  padding: spacing('small') $card-padding;
  background-color: var(--brand-color-primary, palette('secondary'));
  color: palette('background-secondary');
  text-align: center;
}

.c-card__footer-icon {
  font-size: 2.35rem;
}

@include media-query(medium) {
  .c-card {
    margin-bottom: spacing('large');
    padding: $card-padding-medium;
  }

  .c-card--fullheight {
    margin: 0;
  }
}

@include media-query(large) {
  .c-card {
    padding: $card-padding-large;
  }
}

$hover-transition-duration: $bitstyles-transition-duration * 2;

.c-card__slot {
  padding: spacing('base');
  transition: all $hover-transition-duration $bitstyles-transition-easing;
  border: 1.5px solid palette('background', 'dark');
  border-radius: spacing('small');
  background: white;
}

a > .c-card__slot {
  color: black;
}

a:hover > .c-card__slot,
a:focus > .c-card__slot {
  border-color: var(--brand-color-primary, palette('secondary'));
}

.c-card__select {
  width: 100%;
  padding: 0.5rem 0;
  border: 1.5px solid palette('grey', 'dark');
  border-radius: 5px;
  background-color: white;

  select {
    width: 100%;
    height: 100%;
    padding: 0;
    border-top: 0;
    border-right: 15px solid transparent;
    border-bottom: 0;
    border-left: 15px solid transparent;
    border-radius: 5px;
    background-color: inherit;
    font-size: 14pt;
    line-height: 45px;
  }
}

.c-card__select--turquoise {
  border-color: palette('turquoise');
  color: palette('turquoise');

  select,
  select option {
    color: palette('turquoise');
  }
}
/* stylelint-enable selector-max-type */
