.o-section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.o-section:last-child {
  margin: 0;
  padding-bottom: 1rem;
  border: none;
}
