/* stylelint-disable selector-max-type */
@include media-query(medium) {
  $fix-height-float-issue: 8rem;

  .l-image-align {
    min-height: $fix-height-float-issue;

    &,
    & img {
      display: block;
    }
  }

  .l-image-align--left {
    float: left;
  }

  .l-image-align--center {
    margin-right: auto;
    margin-left: auto;
  }

  .l-image-align--right {
    float: right;
  }
}

.l-image-square {
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.l-image-square--medium {
  width: 240px;
  height: 240px;
}

.l-image-square--small {
  width: 120px;
  height: 120px;
}
/* stylelint-enable selector-max-type */
