.c-superscript {
  display: inline;
  position: relative;
  top: -1em;
  left: -0.3em;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.c-superscript--dashboard {
  left: 0;
}
