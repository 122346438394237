.c-details {
  overflow: hidden;
  border: 1.5px solid palette('background', 'dark');
  border-radius: spacing('small');

  &:hover,
  &:focus {
    border-color: var(--brand-color-primary, palette('secondary'));
  }
}

.c-details__summary {
  padding: spacing('small');
  transition: background-color $bitstyles-transition-duration $bitstyles-transition-easing;
  background: palette('background-secondary');
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }
}

.c-details__icon-open {
  display: none;

  [open] & {
    display: block;
  }
}

.c-details__icon-closed {
  [open] & {
    display: none;
  }
}

.c-details__body {
  padding: spacing('base');
  background: palette('background-secondary');
}
