.c-runevaluation {
  margin-top: $topbar-height + 1rem;
  padding: spacing('base');
  background: $bitstyles-color-white;
}

.c-runevaluation__table-wrapper {
  @include media-query(small-only) {
    @include list-shadows;
  }

  max-width: 100%;
  overflow-x: auto;
}

.c-runevaluation--evaluation-table {
  width: auto;
  margin: spacing('base') auto;
}

.c-runevaluation--evaluation-table__highlight {
  background: rgba(palette('background', 'dark'), 0.3);
}

.c-runevaluation-category {
  font-style: italic;
}

.c-runevaluation-category--last {
  border-bottom: none;
}
