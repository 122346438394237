$question-counter-size: 2rem;

.c-question-counter {
  counter-reset: question-counter;
}

.c-question-counter__item {
  counter-increment: question-counter;

  &::before {
    content: counter(question-counter);
    display: flex;
    position: relative;
    z-index: 1;
    top: 50%;
    align-items: center;
    justify-content: center;
    width: $question-counter-size;
    height: $question-counter-size;
    margin: -($question-counter-size * 0.5) spacing('x-small') spacing('x-small');
    border: 2.5px solid var(--chapter-color);
    border-radius: $bitstyles-border-radius-round;
    background: palette('background-secondary');
    font-weight: $bitstyles-font-weight-bold;
  }
}

.c-question-counter__item--table {
  &::before {
    top: 50%;
  }
}

.c-question-counter__item--scroll-container {
  overflow: auto;
}
