.c-flash {
  @include text-shadow;

  position: relative;
  z-index: 1;
  top: $topbar-height;
  padding-top: spacing('base');
  padding-bottom: spacing('base');
  background: palette('notice');
  color: palette('background-secondary');

  .t-padding-topbar--top & {
    top: 0;
  }
}

.c-flash--alert {
  background-color: palette('warning');
}

.c-flash-link {
  color: $bitstyles-color-white;
  text-decoration: underline;

  &:visited {
    color: $bitstyles-color-white;
  }
}
