.o-mini-progress {
  height: 0.35em;
  border: 0;
  -webkit-appearance: none; // stylelint-disable property-no-vendor-prefix
  appearance: none;

  &::-webkit-progress-bar {
    overflow: hidden;
    border-radius: $bitstyles-border-radius-round;
    background-color: palette('background', 'dark');
  }

  &::-moz-progress-bar {
    overflow: hidden;
    border-radius: $bitstyles-border-radius-round;
    background-color: var(--brand-color-primary, palette('primary'));
  }

  &::-webkit-progress-value {
    overflow: hidden;
    border-radius: $bitstyles-border-radius-round;
    background-color: var(--brand-color-primary, palette('primary'));
  }

  &[value] {
    border-radius: $bitstyles-border-radius-round;
  }
}

.o-mini-progress--full-width {
  width: 100%
}

.o-mini-progress--max {
  width: 100%;
}

.o-mini-progress--success {
  &[value] {
    background-color: palette('question-incorrect');
  }

  &::-moz-progress-bar {
    background-color: palette('question-correct');
  }

  &::-webkit-progress-bar {
    background-color: palette('question-incorrect');
  }

  &::-webkit-progress-value {
    background-color: palette('question-correct');
  }
}
