.o-nav-link {
  display: inline-block;
  transition: color $bitstyles-transition-duration $bitstyles-transition-easing, background-color $bitstyles-transition-duration $bitstyles-transition-easing;
  border: none;
  background: none;
  text-decoration: none;
  appearance: none;
}

.o-nav-link__label {
  flex-grow: 1;
  padding-right: spacing('small');
}

.o-nav-link__icon {
  flex-shrink: 0;
}
