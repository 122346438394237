.l-flex {
  @include flex;

  flex-wrap: wrap;
}

.l-flex--inline {
  display: inline-flex;
}

.l-flex--column {
  flex-direction: column;
}

.l-flex--split {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.l-flex--center {
  align-items: center;
}

.l-flex--center__horizontal {
  justify-content: center;
}

.l-flex--baseline {
  align-items: baseline;
}

.l-flex--end {
  justify-content: flex-end;
}

.l-flex--bottom {
  flex-wrap: nowrap;
  align-items: flex-end;
}

.l-flex--fit {
  flex-wrap: nowrap;
}

.l-flex--wrap {
  flex-wrap: wrap;
}

.l-flex--reverse {
  flex-direction: row-reverse;
}

.l-flex__primary {
  flex-grow: 1;
}

.l-flex__fixed {
  flex-shrink: 0;
}

.l-flex__shrink {
  flex-shrink: 1;
}

@include media-query(medium) {
  .l-flex--end\@medium {
    justify-content: flex-end;
  }

  .l-flex--fit\@medium {
    flex-wrap: nowrap;
  }
}

@include media-query(small-only) {
  .l-flex\@small-only {
    display: flex;
  }

  .l-flex--col\@small-only {
    flex-direction: column;
    align-items: stretch;
  }

  .l-flex--center\@small-only {
    align-items: center;
  }

  .l-flex__primary\@small-only {
    flex-grow: 1;
  }
}
