.l-trail-buttons {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
}

.l-trail-buttons--wide {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
}

@include media-query(small-only) {
  .l-trail-buttons--wide {
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  }
}
