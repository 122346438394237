.o-nav-link--menu {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  padding: spacing('x-small') 0;

  &,
  &:visited {
    color: palette('text');
  }

  &:hover,
  &:focus {
    background-color: var(--brand-color-primary);
    color: palette('background-secondary');
  }

  &.is-highlighted {
    text-decoration: underline;
  }
}

@include media-query(large) {
  .o-nav-link--menu {
    padding: 0.75em spacing('base');
  }
}

@include media-query(small-and-medium) {
  .o-nav-link--menu {
    &,
    &:visited {
      color: $bitstyles-color-white;
      font-size: px-to-rem(map-get(map-get($bitstyles-font-sizes, 'base'), 'h3'));
    }

    &:hover,
    &:focus {
      color: var(--brand-color-secondary);
    }
  }
}
