.o-button--icon {
  &,
  &:visited {
    background: $bitstyles-button-icon-background-color;
    color: $bitstyles-button-icon-color;
  }

  &:hover,
  &:focus {
    border-color: var(--brand-color-primary, $bitstyles-button-background-color-hover);
    background: var(--brand-color-primary, $bitstyles-button-background-color-hover);
    color: $bitstyles-button-color-hover;
    text-decoration: none;
  }

  .o-button__label {
    position: relative;
    top: 0.1rem;
  }
}

.o-button--icon-ghost {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;

  &,
  &:visited {
    background: transparent;
    color: $bitstyles-button-icon-color;
  }

  &:hover,
  &:focus {
    background: transparent;
    color: $bitstyles-button-icon-color-hover;
  }
}
