//  TODO: this should be merged with o-button--answer, as it’s really a button, and duplicates styles
.o-answer {
  @include fixed-ratio(1, 1);

  &::before {
    height: 0;
  }
}

.o-answer__label {
  @include fixed-ratio__inner;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: $bitstyles-button-transition;
  border-radius: spacing('x-small');
  background: palette('background');
  cursor: pointer;

  &:hover,
  &:focus {
    background: var(--chapter-color);
  }

  // stylelint-disable-next-line selector-max-type
  input:checked + & {
    background-color: var(--chapter-color-dark);
    color: var(--button-foreground);
  }
}

.o-answer__content {
  padding: spacing('x-small');

  @include media-query(small-only) {
    padding-top: spacing('small');
  }

  .l-image-square--small {
    width: 100%;
    max-width: 120px;
    height: auto;
    max-height: 120px;
  }
}

.o-answer__label .o-answer__content {
  border-color: transparent;
  background: transparent;

  .l-image-align:only-child {
    min-height: 0;
    margin: 0;
  }

  /* stylelint-disable selector-max-type */
  img {
    /* stylelint-disable declaration-no-important */
    margin: 0 !important;
    background-color: white;
    /* stylelint-enable declaration-no-important */
  }
  /* stylelint-enable selector-max-type */
}
