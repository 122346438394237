$bitstyles-button-padding-vertical: 0.71rem;
$bitstyles-button-padding-horizontal: 2.2rem;
$bitstyles-button-background-color: palette('primary');
$bitstyles-button-color: $bitstyles-color-white;
$bitstyles-button-background-color-hover: palette('primary', 'dark');
$bitstyles-button-color-hover: $bitstyles-color-white;
$bitstyles-button-background-color-disabled: $bitstyles-color-gray-30;
$bitstyles-button-border-color-disabled: $bitstyles-color-gray-30;
$bitstyles-button-color-disabled: $bitstyles-color-white;
$bitstyles-button-border: 1px solid;
$bitstyles-button-border-radius: 3rem;
$bitstyles-button-transition:
  color $bitstyles-transition-duration $bitstyles-transition-easing,
  background-color $bitstyles-transition-duration $bitstyles-transition-easing,
  outline-offset $bitstyles-transition-duration $bitstyles-transition-easing;
$bitstyles-button-icon-spacing: 0;
