@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'), url('../../fonts/ProximaNova/ProximaNova-Regular.woff2') format('woff2');
}

@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: bold;
  src: url('../../fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'), url('../../fonts/ProximaNova/ProximaNova-Bold.woff2') format('woff2');
}

@font-face {
  font-family: ProximaNova;
  font-style: italic;
  font-weight: normal;
  src: url('../../fonts/ProximaNova/ProximaNova-RegularIt.woff') format('woff'), url('../../fonts/ProximaNova/ProximaNova-RegularIt.woff2') format('woff2');
}

@font-face {
  font-family: EuclidFlex;
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/EuclidFlex/EuclidFlex-Regular.woff') format('woff'), url('../../fonts/EuclidFlex/EuclidFlex-Regular.woff2') format('woff2');
}

@font-face {
  font-family: EuclidFlex;
  font-style: normal;
  font-weight: bold;
  src: url('../../fonts/EuclidFlex/EuclidFlex-Semibold.woff') format('woff'), url('../../fonts/EuclidFlex/EuclidFlex-Semibold.woff2') format('woff2');
}
