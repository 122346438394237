.t-color--test {
  &,
  &:visited {
    color: var(--brand-color-primary);
  }

  &:focus,
  &:hover {
    color: var(--brand-color-primary-dark);
  }
}

.t-color--test-secondary {
  color: var(--brand-color-secondary);
}

.t-color--subtest {
  color: var(--subtest-brand-color);
}

.t-color--grey-dark {
  color: palette('grey', 'dark');
}

.t-color--turquoise {
  color: palette('turquoise');
}

@include media-query(small-only) {
  .t-color--background-secondary\@small-only {
    color: palette('background-secondary');
  }
}
