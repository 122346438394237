@include media-query('small-only') {
  .t-order-1\@small-only {
    order: 1;
  }

  .t-order-2\@small-only {
    order: 2;
  }

  .t-order-3\@small-only {
    order: 3;
  }
}
