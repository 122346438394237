.t-border-radius-x-small {
  border-radius: spacing('xx-small');
}

.t-border-radius-small {
  border-radius: spacing('small');
}

.t-border-radius {
  border-radius: spacing('base');
}
