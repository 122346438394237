.o-button--ghost {
  /* stylelint-disable declaration-no-important */
  border-color: var(--button-background) !important;
  /* stylelint-enable declaration-no-important */

  &,
  &:visited {
    background: palette('background-secondary');
    color: var(--button-background);
    text-shadow: none;
  }

  &:hover,
  &:focus {
    background: var(--button-background);
    color: palette('background-secondary');
  }

  /* stylelint-disable declaration-no-important */
  &[disabled] {
    border-color: palette('grey', 'dark') !important;
    background: transparent !important;
    color: palette('grey', 'dark') !important;
  }
  /* stylelint-enable declaration-no-important */
}
