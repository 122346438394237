$bitstyles-color-primary: #0af;
$bitstyles-color-secondary: #3f7abd;
$bitstyles-color-text: #3a3a3c;
$bitstyles-color-background: #e9e9e9;
$bitstyles-color-background-secondary: $bitstyles-color-white;

// $bitstyles-color-positive: #0f0;
$bitstyles-color-notice: #22c487;
$bitstyles-color-warning: #ff9800;
$bitstyles-color-negative: #db4437;

// $bitstyles-color-tone-factor: 8%;
$color-question-correct: #43b02a;
$color-question-incorrect: #f9423a;
$color-question-incomplete: $bitstyles-color-gray-20;
$color-question-not-visited: $bitstyles-color-gray-20;
$color-question-visited: $color-question-not-visited;
$color-question-answered: #00f4a3;
$color-highlight: #ffca08;
$color-pale-gray: #dfe0e1;
$color-turquoise: #00baae;

// Colour palette. Each colour has variants (each needs at least the `base`), defined
// in its child map. Naming: `base`, `light`, `lighter`, `dark`, `darker`
// Use Sass map functions to merge in extra colour maps in your project, or re-define
// the whole map.
$bitstyles-palette: (
  'turquoise': (
    'base': $color-turquoise,
    'dark': darken($color-turquoise, $bitstyles-color-tone-factor),
    'light': #f2fcfb
  ),
  'primary': (
    'base': $bitstyles-color-primary,
    'light': lighten($bitstyles-color-primary, $bitstyles-color-tone-factor),
    'lighter': lighten($bitstyles-color-primary, 30%),
    'dark': darken($bitstyles-color-primary, $bitstyles-color-tone-factor)
  ),
  'secondary': (
    'base': $bitstyles-color-secondary,
    'light': lighten($bitstyles-color-secondary, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-secondary, $bitstyles-color-tone-factor)
  ),
  'text': (
    'base': $bitstyles-color-text,
    'light': lighten($bitstyles-color-text, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-text, $bitstyles-color-tone-factor)
  ),
  'text-secondary': (
    'base': $bitstyles-color-gray-50,
    'light': lighten($bitstyles-color-gray-50, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-gray-50, $bitstyles-color-tone-factor)
  ),
  'background': (
    'base': $bitstyles-color-background,
    'light': lighten($bitstyles-color-background, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-background, $bitstyles-color-tone-factor)
  ),
  'background-secondary': (
    'base': $bitstyles-color-background-secondary,
    'light': lighten($bitstyles-color-background-secondary, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-background-secondary, $bitstyles-color-tone-factor)
  ),
  'notice': (
    'base': $bitstyles-color-notice,
    'light': lighten($bitstyles-color-notice, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-notice, $bitstyles-color-tone-factor)
  ),
  'warning': (
    'base': $bitstyles-color-warning,
    'light': lighten($bitstyles-color-warning, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-warning, $bitstyles-color-tone-factor)
  ),
  'error': (
    'base': $bitstyles-color-negative,
    'light': lighten($bitstyles-color-negative, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-negative, $bitstyles-color-tone-factor)
  ),
  'grey': (
    'base': $bitstyles-color-gray-20,
    'light': lighten($bitstyles-color-gray-20, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-gray-20, $bitstyles-color-tone-factor)
  ),
  'light': (
    'base': #f9f9f9,
    'light': lighten(#f9f9f9, $bitstyles-color-tone-factor),
    'dark': darken(#f9f9f9, $bitstyles-color-tone-factor)
  ),
  'question-correct': (
    'base': $color-question-correct,
    'light': lighten($color-question-correct, $bitstyles-color-tone-factor),
    'dark': darken($color-question-correct, $bitstyles-color-tone-factor)
  ),
  'question-incorrect': (
    'base': $color-question-incorrect,
    'light': lighten($color-question-incorrect, $bitstyles-color-tone-factor),
    'dark': darken($color-question-incorrect, $bitstyles-color-tone-factor)
  ),
  'question-incomplete': (
    'base': $color-question-incomplete,
    'light': lighten($color-question-incomplete, $bitstyles-color-tone-factor),
    'dark': darken($color-question-incomplete, $bitstyles-color-tone-factor)
  ),
  'question-not-visited': (
    'base': $color-question-not-visited,
    'light': lighten($color-question-not-visited, $bitstyles-color-tone-factor),
    'dark': darken($color-question-not-visited, $bitstyles-color-tone-factor)
  ),
  'question-visited': (
    'base': $color-question-visited,
    'light': lighten($color-question-visited, $bitstyles-color-tone-factor),
    'dark': darken($color-question-visited, $bitstyles-color-tone-factor)
  ),
  'question-answered': (
    'base': $color-question-answered,
    'light': lighten($color-question-answered, $bitstyles-color-tone-factor),
    'dark': darken($color-question-answered, $bitstyles-color-tone-factor)
  ),
  'badge-score': (
    'base': $bitstyles-color-secondary,
    'light': lighten($bitstyles-color-secondary, $bitstyles-color-tone-factor),
    'dark': darken($bitstyles-color-secondary, $bitstyles-color-tone-factor)
  ),
  'highlight': (
    'base': $color-highlight,
    'light': lighten($color-highlight, $bitstyles-color-tone-factor),
    'dark': darken($color-highlight, $bitstyles-color-tone-factor)
  ),
  'pale-gray': (
    'base': $color-pale-gray,
    'light': lighten($color-pale-gray, $bitstyles-color-tone-factor),
    'dark': darken($color-pale-gray, $bitstyles-color-tone-factor)
  )
);
$foreground-colors: ('primary', 'secondary', 'error', 'background', 'background-secondary', 'grey', 'text-secondary', 'question-incorrect', 'warning', 'highlight');
$background-colors: ('background', 'secondary', 'warning', 'error', 'light', 'grey', 'question-correct', 'pale-gray');
