.t-margin-small--right {
  margin-right: spacing('small');
}

.t-margin-small--bottom {
  margin-bottom: spacing('small');
}

.t-margin-x-small--right {
  margin-right: spacing('x-small');
}

.t-margin-x-small--bottom {
  margin-bottom: spacing('x-small');
}

.t-margin-x-small--left {
  margin-left: spacing('x-small');
}

.t-margin-large--bottom {
  margin-bottom: spacing('large');
}

.t-margin-x-small--top {
  margin-top: spacing('x-small');
}

.t-margin-small--top {
  margin-top: spacing('small');
}

.t-margin--top {
  margin-top: spacing('base');
}

@include media-query(small-only) {
  .t-margin--bottom\@small-only {
    margin-bottom: spacing('base');
  }

  .t-margin-small--bottom\@small-only {
    margin-bottom: spacing('small');
  }
}

@include media-query(medium) {
  .t-margin--right\@medium {
    margin-right: spacing('base');
  }

  .t-margin-small--right\@medium {
    margin-right: spacing('small');
  }
}

.t-margin-negative-large--right {
  margin-right: -#{spacing('large')};
}

.t-margin-negative--bottom {
  margin-bottom: -#{spacing('base')};
}

.t-margin-negative-small--sides {
  margin-right: -#{spacing('small')};
  margin-left: -#{spacing('small')};
}

.t-margin-auto--left {
  margin-left: auto;
}
