/* stylelint-disable selector-max-type */
h1,
h2,
h3,
h4,
h5,
h6 {
  @include generic-heading;
}

@include media-query(medium) {
  html,
  body {
    font-size: $bitstyles-font-size-base;
  }
}
/* stylelint-enable selector-max-type */
