$dropdown-arrow-size: 8px;
$dropdown-highlight-color: $bitstyles-color-white;
$dropdown-highlight-color-test: var(--brand-color-secondary);
$dropdown-border-width: 0;
$dropdown-border-color: $bitstyles-color-white;
$dropdown-border-radius: spacing('small');
$dropdown-width: 18rem;

@include media-query(large) {
  .c-dropdown {
    position: relative;
    max-width: $dropdown-width;
  }

  .c-dropdown__title {
    padding: 0.75em spacing('base');
    color: var(--brand-color-primary);
  }

  .c-dropdown__item {
    border-bottom: 1px solid palette('light', 'dark');

    /* stylelint-disable selector-max-type */
    li:last-child > & {
      border: 0;
    }
    /* stylelint-enable selector-max-type */
  }

  .c-dropdown__button {
    max-width: 100%;
  }

  .c-dropdown__panel {
    visibility: hidden;
    position: absolute;
    z-index: z($bitstyles-global-z, overlay);
    top: 100%;
    width: $dropdown-width;
    transform: translate(0, 0.2rem) scale(0.98);
    transform-origin: 50% 100%;
    -webkit-backface-visibility: hidden; // stylelint-disable-line property-no-vendor-prefix
    backface-visibility: hidden;
    transition:
      opacity $bitstyles-transition-duration $bitstyles-transition-easing,
      visibility $bitstyles-transition-duration 0s linear,
      transform $bitstyles-transition-duration $bitstyles-transition-easing;
    border-radius: $dropdown-border-radius;
    opacity: 0;
    background: palette('background-secondary');
    filter: drop-shadow(0 0 spacing('x-small') rgba($bitstyles-color-black, 0.2));
    will-change: opacity, visibility, transform;

    .c-dropdown--user & {
      right: 0;
      left: auto;
    }

    .c-dropdown--test & {
      right: auto;
      left: 50%;
      margin-left: -($dropdown-width * 0.5);
    }

    .c-dropdown--courses & {
      right: auto;
      left: 50%;
      margin-left: -($dropdown-width * 0.5);
    }
  }

  .c-dropdown--active {
    visibility: visible;
    transform: none;
    opacity: 1;
  }

  .c-dropdown__inner {
    overflow: hidden;
    border-radius: $dropdown-border-radius;
  }

  .c-dropdown__item--sparse {
    padding-right: spacing('base');
    padding-left: spacing('base');
  }
}
