.o-button--round {
  @include fixed-ratio(1, 1);

  width: 100%;
  max-width: 10rem;
  padding: 0;
  overflow: visible;
  border: 0;

  &::after {
    @include absolute-cover(spacing('small'));

    content: '';
    transition: $bitstyles-button-transition;
    border-radius: $bitstyles-border-radius-round;
  }

  &,
  &:visited {
    background-color: transparent;

    &::after {
      background-color: var(--chapter-color);
    }
  }

  &:hover,
  &:focus {
    background-color: transparent;

    &::after {
      background-color: var(--chapter-color-dark);
    }
  }
}

.o-button--round__inner {
  @include fixed-ratio__inner;
}

.o-button-round__icon {
  @include absolute-cover;

  display: block;
  z-index: 1;
  width: 100%;
  height: auto;
  padding: spacing('base');
}
