.rich-text-preview {
  display: flex;
  flex-direction: column-reverse;
  gap: spacing("small");
  align-items: flex-start;
  margin-bottom: spacing("base");
}

.rich-text-preview__content {
  position: relative;
  max-height: 4em;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: spacing("base");
    background: linear-gradient(rgba(255, 255, 255, 0%), rgba(255, 255, 255, 100%));
  }

  &.is-visible {
    max-height: none;

    &::after {
      display: none;
    }
  }
}
