.c-highlight__canvas {
  pointer-events: none;

  &.is-active {
    z-index: 1;
    pointer-events: all;
  }
}

.c-highlight__colors {
  margin-bottom: -#{spacing('x-small')};
}
