@mixin list-shadows($shadow-color: $bitstyles-color-black, $shadow-distance: 1.75%, $shadow-opacity: 0.15, $background-color: $bitstyles-color-white) {
  background:
    /* Shadow covers */
    linear-gradient(to right, $background-color 30%, rgba($background-color, 0)),
    linear-gradient(to left, $background-color, rgba($background-color, 0) 70%),
    /* Shadow */
    radial-gradient(farthest-side at 0 50%, rgba($shadow-color, $shadow-opacity), rgba($background-color, 0)),
    radial-gradient(farthest-side at 100% 50%, rgba($shadow-color, $shadow-opacity), rgba($background-color, 0)) 100% 0;
  background-attachment: local, local, scroll, scroll;
  background-color: $background-color;
  background-repeat: no-repeat;
  background-position: left center, right center, left center, right center;
  background-size: ($shadow-distance * 2) 100%, ($shadow-distance * 2) 100%, $shadow-distance 100%, $shadow-distance 100%;

  @include media-query(small-only) {
    background-size: ($shadow-distance * 2) 100%, ($shadow-distance * 2) 100%, ($shadow-distance * 2) 100%, ($shadow-distance * 2) 100%;
  }
}
