.solutions-grid--header {
  margin-bottom: 1rem;
}

.solutions-grid--questions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(5em, 1fr));
  margin: 0;
  padding: 0;
  list-style: none;
}

.solutions-grid--questions > * {
  display: flex;
  height: 5em;
  margin: 0 0.3rem 0.3rem 0;
}
