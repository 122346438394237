.o-button--chapter-color {
  &,
  &:visited,
  .o-button__badge {
    border-color: var(--chapter-color, $bitstyles-button-background-color);
    background: var(--chapter-color, $bitstyles-button-background-color);
  }

  &:hover,
  &:focus {
    border-color: var(
      --chapter-color-dark,
      $bitstyles-button-background-color-hover
    );
    opacity: 1;
    background: var(
      --chapter-color-dark,
      $bitstyles-button-background-color-hover
    );
  }

  &[disabled] {
    /* stylelint-disable declaration-no-important */
    border-color: var(--chapter-color) !important;
    background: var(--chapter-color) !important;
    /* stylelint-enable declaration-no-important */
  }

  &.is-highlighted {
    box-shadow: 0 0 spacing('x-small') rgba($bitstyles-color-black, 0.2);
  }

  &.not-highlighted {
    opacity: 0.4;
  }
}
