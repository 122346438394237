$progress-list-max-question-count: 30;
$progress-list-overlap: 0.5rem;

.c-progress-list-wrapper {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  padding: 0 0 spacing('large');
}

.c-progress-list {
  display: flex;
  position: relative;
  z-index: 1;
  flex-grow: 1;
  align-content: stretch;
  align-items: stretch;
  height: 1.2em;
  padding-right: 0.1rem;
  overflow: hidden;
  border-radius: $bitstyles-border-radius-round 0 0 $bitstyles-border-radius-round;
}

.c-progress-list__item {
  position: relative;
  flex: 1 1 auto;
  margin-right: 2px;

  &:last-child {
    margin-right: 0;
  }

  /* stylelint-disable at-rule-no-unknown */
  @for $i from 1 through $progress-list-max-question-count {
    &:nth-child(#{$i}) {
      z-index: #{$progress-list-max-question-count - $i};
    }
  }
  /* stylelint-enable at-rule-no-unknown */
}

.c-progress-list__subject {
  margin-left: -$progress-list-overlap;
}
