.c-modal {
  position: fixed;
  z-index: 1;
  inset: 0;
  background-color: palette('secondary', 'dark');
}

.c-modal__content {
  @include absolute-cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: spacing('large') 0;
}

.c-modal__close {
  position: absolute;
  top: 0;
  right: 0;
}

@include media-query(medium) {
  .c-modal__content {
    padding: spacing('large');
  }
}
