$answer-grid-gap: spacing('x-small');
$header-spacing: spacing('large');

.c-bordered-answers {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -$answer-grid-gap;
    bottom: -$answer-grid-gap;
    left: 1.25rem;
    border: 1.5px solid var(--chapter-color);
  }

  .l-answer-wrapper:first-child &::after {
    top: 0;
  }

  .l-answer-wrapper:last-child &::after {
    bottom: 50%;
  }
}

.c-bordered-answers--header::after {
  top: spacing('base');
  bottom: -$header-spacing;
}
