$header-height: 71px;

.t-position--relative {
  position: relative;
}

.t-position--sticky {
  position: sticky;
  top: $header-height;
}
