.o-button--color {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-color: $bitstyles-color-gray-20;
  background-color: $bitstyles-color-white;

  &:hover,
  &:focus {
    border-color: palette('primary', 'dark');
    background-color: palette('primary', 'dark');
  }

  &[aria-pressed="true"] {
    border-color: palette('primary');
    background-color: palette('primary');
  }
}
