$answer-grid-gap: spacing('x-small');

.c-answer-table-wrapper {
  position: relative;
  overflow: auto;

  &::after {
    @include absolute-cover;

    content: '';
    display: block;
    right: auto;
    width: spacing('x-small');
    border-radius: $bitstyles-border-radius-round;
    background: var(--chapter-color, var(--brand-color-primary));
  }
}

.c-answer-table {
  @include overflow;

  max-width: 100%;
  max-height: none;
  text-align: center;

  // need to fight against _table.scss
  /* stylelint-disable selector-max-type */
  tr {
    border: 0;

    &:hover {
      background-color: inherit;
      cursor: inherit;
    }

    &:focus {
      outline: inherit;
      outline-offset: inherit;
    }
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
  }

  .c-answer-table__bordered {
    border-top: 2px solid var(--chapter-color, var(--brand-color-primary));
  }

  .c-answer-table__bordered-bottom {
    border-bottom: 2px solid var(--chapter-color, var(--brand-color-primary));
  }
  /* stylelint-enable selector-max-type */
}

/* stylelint-disable selector-max-type, no-descending-specificity */
.c-answer-table--no-headers {
  th {
    @include visuallyhidden;
  }
}
/* stylelint-enable selector-max-type, no-descending-specificity */

.c-answer-table__cell {
  padding: $answer-grid-gap 0 0 $answer-grid-gap;
}

.c-answer-table__answer {
  &.no-content {
    @include fixed-ratio(1, 1);

    width: 5em;
    height: 5em;
    margin: 0 auto;

    > * {
      @include absolute-cover;

      width: 100%;
    }

    @include media-query(small-only) {
      width: 3em;
      height: 3em;
    }
  }
}

.c-answer-table__label {
  width: min-content;
}

@include media-query(medium) {
  .c-answer-list-wrapper {
    &::after {
      width: spacing('small');
    }
  }
}
