// $bitstyles-input-color: palette('text');
// $bitstyles-input-padding: spacing('small');
$bitstyles-input-border: 0;
$bitstyles-input-border-radius: spacing('x-small');
$bitstyles-input-border-active: 0;

// $bitstyles-input-background: $bitstyles-color-white;
// $bitstyles-input-placeholder-color: $bitstyles-color-gray-50;
//
// $bitstyles-input-background-color-disabled: $bitstyles-color-gray-10;
// $bitstyles-input-color-disabled: palette('text');
//
// $bitstyles-input-checkbox-size: spacing('base');
