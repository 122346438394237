/* stylelint-disable selector-max-type */

.zebra-table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.zebra-table {
  td,
  th {
    padding-top: spacing("x-small");
    padding-bottom: spacing("x-small");
  }

  th {
    white-space: nowrap;
  }

  tbody tr {
    border-bottom: 0;

    &:hover {
      background-color: transparent;
    }
  }

  thead {
    th {
      font-weight: normal;
    }
  }

  /* stylelint-disable no-descending-specificity */
  tr:nth-child(even) {
    td,
    th {
      background-color: palette('pale-gray', 'light');
    }
  }
  /* stylelint-enable no-descending-specificity */
}

/* stylelint-enable selector-max-type */
