$logo-size: 2.8rem;

.o-icon--logo {
  width: auto;
  height: $logo-size;
}

.o-icon--logo-small {
  width: $logo-size;
  height: $logo-size;
}

.o-icon--100 {
  min-width: 100%;
  height: 100%;
}

.o-icon--50 {
  max-width: 50%;
  height: auto;
}

.o-icon--block {
  display: block;
}
