.t-background-color--test {
  background-color: var(--brand-color-primary);
}

.t-background-color--test-secondary {
  background-color: var(--brand-color-secondary);
}

.t-background-color--subtest {
  background-color: var(--subtest-brand-color);
}

.t-background-color--editor {
  background-color: palette('pale-gray', 'light');
}
