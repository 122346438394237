.o-button--secondary {
  text-shadow: none;

  &,
  &:visited {
    border-color: $bitstyles-color-white;
    background-color: $bitstyles-color-white;
    color: palette('primary');
  }

  &:hover,
  &:active {
    border-color: palette('primary');
    background-color: palette('primary');
    color: $bitstyles-color-white;
  }
}
