$bitstyles-global-z: base, content, topbar, overlay;

// $bitstyles-border-radius-round: 9999rem;
//
$bitstyles-spacing: 1.3rem;
$bitstyles-spacing-sizes: (
  'base': $bitstyles-spacing,
  'small': $bitstyles-spacing * 0.5,
  'x-small': $bitstyles-spacing * 0.25,
  'xx-small': $bitstyles-spacing * 0.15,
  'large': $bitstyles-spacing * 2,
  'x-large': $bitstyles-spacing * 5
);
$topbar-height: 4.3rem;
