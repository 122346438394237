.o-button--topbar {
  &,
  &:visited {
    background: none;
  }

  &:hover,
  &:focus {
    background: $bitstyles-color-white;
    color: var(--brand-color-secondary);
  }
}
