$drop-shadow: 0 0.15rem 0.3rem rgba($bitstyles-color-black, 0.1);
$drop-shadow-hover: 0 0 0.1rem rgba($bitstyles-color-black, 0.1);

.c-test-link {
  display: block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: box-shadow $bitstyles-transition-duration $bitstyles-transition-easing;
  border-radius: 0.6rem;
  background: var(--button-base) linear-gradient(to bottom, var(--button-base), var(--button-dark));
  box-shadow: $drop-shadow;

  &::after {
    @include absolute-cover;

    content: '';
    z-index: 1;
    transition: opacity $bitstyles-transition-duration $bitstyles-transition-easing;
    border-radius: 0.6rem;
    opacity: 0;
    background: linear-gradient(to top, var(--button-base), var(--button-dark));
  }

  &:hover,
  &:focus {
    box-shadow: $drop-shadow-hover;

    &::after {
      opacity: 1;
    }
  }
}

.c-test-link__subscribed {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  padding: 15% 0 (spacing('x-small') * 0.75);
  transform: translate3d(50%, 0, 0) rotate(45deg);
  transform-origin: 50% 0;
  backface-visibility: visible;
  background: var(--button-background);
  box-shadow: 0 0 spacing('small') var(--button-dark);
  color: var(--button-foreground);
  font-size: 0.8em;
  text-align: center;
}

.c-test-image__inner {
  @include fixed-ratio(1, 1);
}

.c-test-link__image {
  @include fixed-ratio__inner;

  z-index: 2;
  filter: drop-shadow($drop-shadow);
}

.c-test-link__button {
  position: absolute;
  z-index: 3;
  bottom: spacing('small');
  left: spacing('small');
  border: 0;

  &,
  &:hover,
  &:focus {
    background: var(--button-background);
    color: var(--button-foreground);
  }
}

@include media-query(medium) {
  .c-test-link__subscribed {
    padding-top: 12% 0 spacing('x-small');
    font-weight: $bitstyles-font-weight-bold;
  }
}
