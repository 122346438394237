@mixin arrow-base($arrow-width, $arrow-color, $border-width, $border-color) {
  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: solid transparent;
    pointer-events: none;
  }

  &::after {
    margin-left: -$arrow-width;
    border-width: $arrow-width;
    border-color: rgba($border-color, 0);
  }

  &::before {
    border-width: calc(#{$arrow-width} + #{$border-width});
    border-color: rgba($border-color, 0);
  }
}

@mixin arrow-top($arrow-width, $arrow-color, $border-width, $border-color) {
  &::after,
  &::before {
    bottom: 100%;
    left: 50%;
  }

  &::after {
    border-bottom-color: $arrow-color;
  }

  &::before {
    margin-left: calc(-#{$arrow-width} - #{$border-width});
    border-bottom-color: $border-color;
  }
}

@mixin arrow-top-right($arrow-width, $arrow-color, $border-width, $border-color) {
  &::after,
  &::before {
    right: 4%;
    bottom: 100%;
  }

  &::after {
    border-bottom-color: $arrow-color;
  }

  &::before {
    margin-left: calc(-#{$arrow-width} - #{$border-width});
    border-bottom-color: $border-color;
  }
}
