$intro-body-arrow-size: 1.8rem;

.c-dashboard-intro {
  display: flex;
  position: relative;
  align-items: center;
}

.c-dashboard-intro__body {
  position: relative;
  z-index: 2;
  flex: 1 0 71%;
}

.c-dashboard-intro__image {
  max-height: 30vmin;
  object-fit: contain;
}

.c-dashboard-intro__image-wrapper {
  position: relative;
  z-index: 2;
  left: spacing('base');
  max-width: 40%;
}

.c-dashboard-intro__button {
  position: relative;
  z-index: 3;
}

@include media-query(medium) {
  .c-dashboard-intro__body {
    padding: spacing('base') * 1.5;
    border: 1px solid palette('background', 'dark');
    border-radius: spacing('small');
    background: palette('light');

    &::after {
      $pixel-rounding-error-fix: 0.03rem;

      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: -($intro-body-arrow-size - $pixel-rounding-error-fix);
      width: $intro-body-arrow-size;
      height: $intro-body-arrow-size;
      transform: translate(0, -50%);
      background: url('../../images/speech-bubble.svg') 0 0 no-repeat;
      background-size: 100%;
    }
  }
}

@include media-query(small-only) {
  $image-width: 30%;

  .c-dashboard-intro {
    margin-top: -#{spacing('small')};
    margin-bottom: spacing('small');
  }

  .c-dashboard-intro__body {
    padding-right: $image-width * 0.5;
  }

  .c-dashboard-intro__image-wrapper {
    position: relative;
    left: 2rem;
    align-self: flex-start;
    max-width: $image-width;
    margin-left: -$image-width;
  }
}
