.o-price {
  position: relative;
  padding: 0 0.4em;
  font-weight: $bitstyles-font-weight-bold;
}

.o-price__quantity {
  @include set-font-size(map-get(map-get($bitstyles-font-sizes, 'base'), 'h2'));
}

.o-price__prefix {
  @include set-font-size(map-get(map-get($bitstyles-font-sizes, 'base'), 'h6'));

  display: block;
  position: relative;
  top: 0.25em;
  padding-left: 0.75em;
  font-weight: $bitstyles-font-weight-normal;
  line-height: 0.75;
  text-align: left;
}

@include media-query(medium) {
  .o-price__quantity {
    @include set-font-size(map-get(map-get($bitstyles-font-sizes, 'base'), 'h2'), $bitstyles-font-size-base);
  }

  .o-price__prefix {
    @include set-font-size(map-get(map-get($bitstyles-font-sizes, 'base'), 'h6'), $bitstyles-font-size-base);
  }
}
