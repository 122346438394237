$runplayer-padding: spacing('base');
$runplayer-nav-width: 5rem;
$runplayer-z-index: content, control-bar;

.c-runplayer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.c-runplayer__primary {
  flex: 1 1 0%;
  max-width: 100%;
  margin-bottom: spacing('base');
  padding: $runplayer-padding;
  overflow: visible; // this enables the sticky highlighting controls
  background: $bitstyles-color-white;
}

.c-runplayer__controls {
  margin: $runplayer-padding * 2 (-$runplayer-padding) (-$runplayer-padding);
  padding: $runplayer-padding;
  border-radius: 0 0 spacing('small') spacing('small');
  background: palette('background', 'dark');
}

.c-runplayer__content {
  z-index: z($runplayer-z-index, content);
}

.c-runplayer__control-bar-wrapper {
  position: sticky;
  z-index: z($runplayer-z-index, control-bar);
  top: $topbar-height + spacing('x-small');
}

.c-runplayer__control-bar {
  border-radius: spacing('base');
  background-color: $bitstyles-color-white;

  &.is-active {
    padding: spacing('x-small');
    box-shadow: 0 0 1rem rgba(palette('text'), 0.1);
  }
}

@include media-query(medium) {
  .c-runplayer__control-bar {
    border-radius: $bitstyles-border-radius-round;

    &,
    &.is-active {
      padding: 2px;
    }
  }
}

.c-runplayer__nav {
  max-width: 100%;
}

@include media-query(small-only) {
  .c-runplayer__primary {
    width: 100%;
  }
}

@include media-query(medium) {
  .c-runplayer {
    flex-direction: row;
    justify-content: stretch;
  }

  .c-runplayer__primary {
    max-width: calc(100% - #{$runplayer-nav-width});
  }

  .c-runplayer__controls {
    flex: 0 0 $runplayer-nav-width;
  }
}
