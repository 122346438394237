.l-finished-runs {
  display: grid;
  grid-gap: spacing('base');
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
}

@include media-query(large) {
  .l-finished-runs {
    grid-gap: spacing('small');
    grid-template-columns: repeat(auto-fit, 7rem);
  }
}
