/*
Intentionally copied from
https://raw.githubusercontent.com/thoughtbot/administrate/master/app/assets/stylesheets/administrate/base/_tables.scss
because we want to display tables in the run-player similar
to how we display them in the administrate-backend.
*/

/* stylelint-disable selector-max-type */
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  text-align: left;

  a {
    color: inherit;
    text-decoration: none;
  }
}

thead {
  font-weight: $bitstyles-font-weight-bold;
}

tr {
  border-bottom: 1px solid palette('pale-gray');
}

tbody tr {
  &:hover {
    background-color: rgba($bitstyles-color-black, 0.05);
    cursor: pointer;
  }

  &:focus {
    outline: 3px solid rgba(25, 118, 210, 60%);
    outline-offset: -3px;
  }
}

td,
th {
  padding: 0.75rem;
  vertical-align: middle;
  font-feature-settings: "kern", "liga", "clig", "calt", "lnum", "tnum";
  font-kerning: normal;
  font-variant-ligatures: common-ligatures, contextual;
  font-variant-numeric: lining-nums, tabular-nums;
}

td img {
  max-height: 2rem;
}
/* stylelint-enable selector-max-type */
