$nav-width: 100vw;
$topbar-height-smallscreen: 4rem;

@include media-query(large) {
  .c-nav {
    display: flex;
    align-items: center;
  }
}

@include media-query(small-and-medium) {
  .c-nav {
    @include overflow;

    position: fixed;
    z-index: z($bitstyles-global-z, topbar);
    top: $topbar-height-smallscreen;
    right: -$nav-width;
    bottom: 0;
    width: $nav-width;
    transition: transform $bitstyles-transition-duration ease, opacity $bitstyles-transition-duration ease;
    opacity: 0;
    background: var(--brand-color-primary);
  }

  .c-nav--active {
    transform: translate3d(-$nav-width, 0, 0);
    opacity: 1;
  }

  .c-nav__section {
    margin: 0 spacing('base');
    padding: spacing('base') 0;
    border-bottom: 1px solid #fff;
  }
}
