/* stylelint-disable selector-max-type */
.c-tiptap-content-viewer {
  img,
  table,
  .l-image-align {
    margin-bottom: 1.3em;

    &:not(:only-child) {
      margin-top: 1em;
    }

    &:only-child {
      margin-top: -0.6em;
    }
  }

  /* stylelint-disable no-descending-specificity */
  img,
  table {
    margin-right: 0;
    margin-left: 0;
  }

  .l-image-align {
    img {
      margin-top: 0.3em;
      margin-bottom: 0;
    }
  }
  /* stylelint-enable no-descending-specificity */

  p:last-child,
  p:has(+ .l-image-align:not(.l-image-align--center)) {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.4rem;
    font-weight: 600;
  }
}

.ProseMirror {
  ol {
    margin-left: spacing('large');
    list-style-type: upper-roman;
  }

  ul {
    margin-left: spacing('large');
    list-style-type: disc;
  }
}

@include media-query(small-only) {
  .c-tiptap-content-viewer {
    table {
      @include list-shadows;

      display: block;
      width: fit-content;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
      overflow-x: auto;
    }

    td {
      white-space: nowrap;
    }
  }
}

.selectedCell {
  background-color: rgba(25, 118, 210, 30%);
}

/* stylelint-enable selector-max-type */
