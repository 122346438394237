$answer-grid-gap: spacing('x-small');

.l-answer-grid {
  display: grid;
  grid-gap: $answer-grid-gap;
  grid-template-columns: 4rem repeat(5, 1fr);
}

.l-answer-grid__main {
  grid-column: 2 / -1;
  align-self: center;
}

.l-answer-grid__marker {
  position: relative;
  z-index: 1;
}

.l-answer-grid__table {
  margin-top: #{spacing('large')};
}
