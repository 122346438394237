.o-nav-link--button {
  padding: 0.5rem 1rem;
  border-radius: $bitstyles-button-border-radius;

  &,
  &:visited {
    background-color: transparent;
    color: palette('background-secondary');
  }

  &:hover,
  &:focus {
    background-color: var(--brand-color-primary-dark);
    color: palette('background-secondary');
  }

  &.is-highlighted {
    background-color: var(--brand-color-primary-dark);
  }
}
