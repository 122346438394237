//
// Base styles ////////////////////////////////////////

$bitstyles-checkbox-border-radius: spacing('small') * 0.3333;

//
// Base colors ////////////////////////////////////////
$bitstyles-checkbox-color: palette('background-secondary');
$bitstyles-checkbox-background-color: palette('secondary');
$bitstyles-checkbox-border: 1px solid;

//
// Hover colors ////////////////////////////////////////
$bitstyles-checkbox-color-hover: palette('background-secondary');
$bitstyles-checkbox-background-color-hover: palette('background-secondary');
$bitstyles-checkbox-border-hover: 1px solid;

//
// Checked colors ////////////////////////////////////////
$bitstyles-checkbox-color-checked: palette('background');
$bitstyles-checkbox-background-color-checked: palette('primary');
$bitstyles-checkbox-border-checked: 1px solid palette('primary');
$bitstyles-checkbox-background-image-checked: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1"><path fill="white" d="m83.07 11.71-44.41 44.59-21.73-21.81-15.93 15.99 37.65 37.81 60.35-60.59z" /></svg>');

//
// Disabled colors ////////////////////////////////////////
$bitstyles-checkbox-color-disabled: palette('text', 'light');
$bitstyles-checkbox-background-color-disabled: palette('background', 'dark');
$bitstyles-checkbox-border-disabled: 1px solid;

//
// Disabled-checked colors ////////////////////////////////////////
$bitstyles-checkbox-color-disabled-checked: palette('text', 'light');
$bitstyles-checkbox-background-color-disabled-checked: palette('background', 'dark');
$bitstyles-checkbox-border-disabled-checked: 1px solid palette('background', 'dark');
