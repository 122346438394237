.o-nav-link--inactive {
  &,
  &:visited {
    color: palette('grey', 'dark');
  }

  &:hover,
  &:focus {
    color: $bitstyles-color-white;
  }

  .o-nav-link__icon {
    opacity: 0.8;
  }
}
