.t-font-family--header {
  &,
  .fonts-loaded & {
    font-family: $bitstyles-font-family-header;
    text-transform: uppercase;
  }
}

.t-font-family--body {
  &,
  .fonts-loaded & {
    font-family: $bitstyles-font-family-body-loaded;
  }
}
