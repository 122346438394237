.t-padding {
  padding: spacing('base');
}

.t-padding--ends {
  padding-top: spacing('base');
  padding-bottom: spacing('base');
}

.t-padding-small--top {
  padding-top: spacing('small');
}

.t-padding-small--right {
  padding-right: spacing('small');
}

.t-padding-small--left {
  padding-left: spacing('small');
}

.t-padding-small--bottom {
  padding-bottom: spacing('small');
}

.t-padding-x-small--right {
  padding-right: spacing('x-small');
}

.t-padding-x-small--left {
  padding-left: spacing('x-small');
}

.t-padding-x-small--sides {
  padding-right: spacing('x-small');
  padding-left: spacing('x-small');
}

.t-padding-large--ends {
  padding-top: spacing('large');
  padding-bottom: spacing('large');
}

.t-padding-large--top {
  padding-top: spacing('large');
}

.t-padding-large--right {
  padding-right: spacing('large');
}

.t-padding-topbar--top {
  padding-top: $topbar-height;
}

.t-padding--sides {
  padding-right: spacing('base');
  padding-left: spacing('base');
}

.t-padding-small--sides {
  padding-right: spacing('small');
  padding-left: spacing('small');
}

@include media-query(small-only) {
  .t-padding\@small-only {
    padding: spacing('base');
  }
}

@include media-query(medium) {
  .t-padding--left\@medium {
    padding-left: spacing('base');
  }

  .t-padding-large--top\@medium {
    padding-top: spacing('large');
  }

  .t-padding-large--left\@medium {
    padding-left: spacing('large');
  }

  .t-padding-large--sides\@medium {
    padding-right: spacing('large');
    padding-left: spacing('large');
  }

  .t-padding-topbar--top\@medium {
    padding-top: $topbar-height;
  }
}
