.o-button--square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-radius: 0.2em;
  color: $bitstyles-color-white;
  font-size: 1.5em;
  font-weight: $bitstyles-font-weight-normal;
  text-decoration: none;
}

.o-button--square.is-correct {
  background: palette('question-correct');

  &:hover,
  &:focus {
    @include text-shadow;

    background: palette('question-correct', 'dark');
    color: $bitstyles-color-white;
  }
}

.o-button--square.is-incorrect {
  background: palette('question-incorrect');

  &:hover,
  &:focus {
    @include text-shadow;

    background: palette('question-incorrect', 'dark');
    color: $bitstyles-color-white;
  }
}
