$grid-item-min-size: 8rem;

.l-flex-grid {
  .l-grid__item {
    margin-bottom: spacing('base');
  }
}

@include media-query(medium) {
  .l-flex-grid--2-col\@large {
    .l-grid__item {
      flex-basis: 50%;
    }
  }

  .l-flex-grid {
    .l-grid__item {
      margin-bottom: spacing('large');
    }
  }
}

@include media-query(large) {
  .l-flex-grid--3-col\@large {
    .l-grid__item {
      flex-basis: 33%;
    }
  }
}

@supports (display: grid) {
  .l-flex-grid {
    display: grid;
    grid-gap: spacing('base');
    grid-template-columns: repeat(auto-fill, minmax($grid-item-min-size, 1fr));
    margin: 0;

    .l-grid__item {
      width: auto;
      margin: 0;
      padding: 0;
    }
  }

  .l-flex-grid--1-col,
  .l-flex-grid--2-col\@large,
  .l-flex-grid--3-col\@large {
    grid-template-columns: minmax(0, 1fr);
  }

  .l-flex-grid__end {
    align-self: end;
  }

  @include media-query(medium) {
    /* stylelint-disable scss/no-duplicate-dollar-variables */
    $grid-item-min-size: 20%;
    /* stylelint-enable scss/no-duplicate-dollar-variables */

    .l-flex-grid {
      grid-template-columns: repeat(
        auto-fill,
        minmax($grid-item-min-size, 1fr)
      );
    }

    .l-flex-grid--1-col {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  @include media-query(large) {
    .l-flex-grid {
      grid-gap: spacing('large');
    }

    .l-flex-grid--tight {
      grid-gap: spacing('small');
    }

    .l-flex-grid--2-col\@large {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .l-flex-grid--3-col\@large {
      grid-template-columns: repeat(3, minmax(0, 1fr));

      > .l-grid__item {
        display: grid;
        grid-row: 1 / 4;
        grid-template-rows: subgrid;
      }
    }
  }
}

@supports not (grid-template-rows: subgrid) {
  .l-flex-grid__end {
    margin-bottom: spacing('base');
  }

  @include media-query(medium) {
    .l-flex-grid__intro {
      min-height: 21em;
    }

    .l-flex-grid__intro-ranking {
      min-height: 24.5em;
    }
  }
}
