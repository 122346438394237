.o-metadata {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.o-metadata > *,
.o-metadata__item {
  display: flex;
  flex-shrink: 0;
  margin: 0;
}

.o-metadata__item--highlighted::after {
  content: '·';
  padding: 0 spacing('x-small');
}

.o-metadata__item--large {
  flex: 1 1 100%;
}
