$grid-gutter-small-only: spacing('base');

@include media-query(small-only) {
  .l-grid {
    margin-left: -$grid-gutter-small-only;
  }

  .l-grid__item {
    padding-left: $grid-gutter-small-only;
  }
}

@supports (display:grid) {
  .l-grid--3-col {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    margin: 0;
    padding: 0;

    > * {
      width: auto;
      padding: 0;
    }
  }
}
