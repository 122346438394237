.o-button--answer {
  padding: spacing('small') spacing('small') spacing('small') (spacing('large') + spacing('small'));
  border: 1px solid palette('grey');
  border-radius: spacing('x-small');
  background: transparent;
  color: inherit;
  text-align: left;
  text-shadow: none;

  /* Overwrite the bitstyles hover for touch-only devices to look like they are not hovering.
     This fixes an issue on safari for iPhone where the phone simulates mouse-hover
     when un-selecting an answer.
  */
  &:focus,
  &:hover {
    border-color: palette('grey');
    background: transparent;
    color: inherit;
  }

  &.is-correct {
    font-weight: $bitstyles-font-weight-bold;
  }

  &.no-content {
    background: palette('background');
  }

  &[aria-pressed="true"] {
    border-color: var(--chapter-color, var(--brand-color-primary));
    background: var(--chapter-color, var(--brand-color-primary));
    color: var(--button-foreground);

    &.is-correct {
      border-color: $color-question-correct;
      background: $color-question-correct;
      color: var(--button-foreground);
    }

    &.is-incorrect {
      border-color: $color-question-incorrect;
      background: $color-question-incorrect;
      color: var(--button-foreground);
    }
  }

  /* devices without touch input are assumed to have a mouse and thus support hovering. */
  .no-touch & {
    &:focus,
    &:hover {
      border-color: var(--chapter-color-dark, var(--brand-color-primary-dark));
      background: var(--chapter-color-dark, var(--brand-color-primary-dark));
      color: var(--button-foreground);
    }
  }
}

.o-button--answer-horizontal {
  padding: spacing('small');
  border: none;
}

.o-button--answer-table {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: spacing('small');
  text-align: center;
}
