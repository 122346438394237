/* stylelint-disable selector-max-type */
html {
  height: 100%;
}

body {
  min-height: 100%;
}

/* stylelint-enable selector-max-type */
