// $bitstyles-background-color-link: none;
$bitstyles-color-link: palette('primary');
$bitstyles-color-link-visited: palette('primary', 'light');

// $bitstyles-color-link-hover:      $bitstyles-color-gray-20;
// $bitstyles-link-transition: color $bitstyles-transition-duration $bitstyles-transition-easing;
$bitstyles-text-decoration-link: none;
$bitstyles-text-decoration-link-hover: none;

// $bitstyles-text-decoration-skip-link: ink;
