.o-button--test-color {
  &,
  &:visited,
  .o-button__badge {
    border-color: var(--button-background);
    background: var(--button-background);
    color: var(--button-foreground);
  }

  &:hover,
  &:focus {
    border-color: var(--button-dark);
    background: var(--button-dark);
  }
}

.o-button--fallback-color {
  &,
  &:visited {
    border-color: var(--subtest-brand-color, var(--brand-color-primary));
    background-color: var(--subtest-brand-color, var(--brand-color-primary));
  }

  &:hover,
  &:focus {
    border-color: var(--subtest-brand-color-dark, var(--button-dark));
    background-color: var(--subtest-brand-color-dark, var(--button-dark));
  }
}
