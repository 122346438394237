@mixin generic-heading {
  margin-top: 0;
  margin-bottom: spacing('base') * 1.3;
  font-weight: $bitstyles-font-weight-normal;
  line-height: 1.3;

  @if $bitstyles-font-family-header-loaded != $bitstyles-font-family-header {
    .fonts-loaded & {
      font-family: $bitstyles-font-family-header-loaded;
    }
  }
}
