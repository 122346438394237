$link-white-color: palette('background-secondary');

.o-link--white {
  color: $link-white-color;

  &:visited {
    color: rgba($link-white-color, 0.5);
  }

  &:hover,
  &:focus {
    color: rgba($link-white-color, 0.7);
  }
}
