.o-nav-link--logo {
  &,
  &:visited {
    color: var(--brand-color-primary-dark);
  }

  &:hover,
  &:focus {
    color: var(--brand-color-secondary);
  }
}
