/* stylelint-disable  no-descending-specificity */
.c-trail-link__badge {
  @include fixed-ratio(1, 1);

  width: auto;
  max-width: 108px;
  height: auto;
  margin-right: auto;
  margin-bottom: spacing('base');
  margin-left: auto;
  padding: spacing('base');
  overflow: visible;
  transition: background-color $bitstyles-transition-duration $bitstyles-transition-easing, transform $bitstyles-transition-duration $bitstyles-transition-easing;
  background-color: palette('grey', 'light');
}

.c-trail-link__badge-label {
  min-width: 5rem;
  min-height: 5rem;
}

.c-trail-link {
  display: block;
  text-align: center;
  hyphens: auto;

  &,
  &:visited,
  &:hover,
  &:focus {
    color: palette('grey');
  }

  &.is-active,
  &.is-complete {
    color: var(--subtest-brand-color, var(--brand-color-primary));

    .c-trail-link__badge {
      background-color: var(--trail-color, var(--subtest-brand-color, var(--brand-color-primary)));
      background-image: radial-gradient(var(--trail-color, var(--subtest-brand-color, var(--brand-color-primary))), var(--trail-color-dark, var(--subtest-brand-color-dark, var(--brand-color-primary-dark))));
    }

    .c-trail-link__badge-label {
      filter: drop-shadow(0 0.15rem 0.3rem rgba($bitstyles-color-black, 0.1));
    }

    &:hover,
    &:focus {
      color: var(--subtest-brand-color-dark, var(--brand-color-primary-dark));

      /* stylelint-disable max-nesting-depth */
      .c-trail-link__badge {
        background-color: var(--trail-color, var(--subtest-brand-color, var(--brand-color-primary)));
      }
      /* stylelint-enable max-nesting-depth */
    }
  }

  &.is-complete {
    /* stylelint-disable max-nesting-depth */
    .c-trail-link__badge {
      border: 3px solid var(--brand-color-primary);

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: spacing('x-small');
        bottom: spacing('x-small');
        width: 1.5rem;
        height: 1.5rem;
        border-radius: $bitstyles-border-radius-round;
        background: var(--brand-color-primary) url("../../images/check.svg") no-repeat 50% 50%;
        background-size: 70%;
      }
    }
    /* stylelint-enable max-nesting-depth */
  }

  &.is-active {
    .c-trail-link__badge {
      box-shadow: 0 0.2rem 0.5rem rgba($bitstyles-color-black, 0.1);
    }

    &:hover,
    &:focus {
      /* stylelint-disable max-nesting-depth */
      .c-trail-link__badge {
        transform: scale(1.05);
      }
      /* stylelint-enable max-nesting-depth */
    }
  }
}
/* stylelint-enable  no-descending-specificity */
