$ranking-color-highlight: #f5a259;

.c-ranking-ring {
  position: relative;
}

.c-ranking-ring__ring {
  width: 100%;
  height: auto;
}

.c-ranking-ring__background {
  fill: palette('background');
}

.c-ranking-ring__bottom {
  fill: palette('question-incorrect');
}

.c-ranking-ring__middle {
  fill: $ranking-color-highlight;
}

.c-ranking-ring__top {
  fill: palette('question-correct');
}

.c-ranking-ring__label {
  fill: palette('grey', 'dark');
  font-size: 6px;
}

.c-ranking-ring__trophy {
  fill: palette('highlight');
  fill-rule: evenodd;
}

.c-ranking-ring__progress {
  animation: draw-ranking-ring linear 1s;
  stroke-width: 7.5;
  stroke-linecap: round;
  fill: none;
}

.c-ranking-ring__progress--bottom {
  stroke: palette('question-incorrect');
}

.c-ranking-ring__progress--middle {
  stroke: $ranking-color-highlight;
}

.c-ranking-ring__progress--top {
  stroke: palette('question-correct');
}

.c-ranking-ring__score {
  top: 40%;
  padding-right: 0.2em;
  font-size: 2.5rem;
  line-height: 1;
}

.c-ranking-ring__score-percent-label {
  margin-right: -1em;
  font-size: 0.6em;
}

@keyframes draw-ranking-ring {
  from {
    /* stylelint-disable number-max-precision */
    stroke-dashoffset: 182.3694535408875; // magic number, based on sizes in SVG
    /* stylelint-enable number-max-precision */
  }
}
