$card-padding: spacing('base');

.c-results-card {
  height: 100%;
  padding: $card-padding;
}

.c-results-card__no-header {
  padding-top: px-to-rem(map-get(map-get($bitstyles-font-sizes, 'base'), 'h2') * $bitstyles-line-height-base) + spacing('base');
}

@include media-query(medium) {
  .c-results-card__no-header {
    padding-top: px-to-rem(map-get(map-get($bitstyles-font-sizes, 'base'), 'h2') * $bitstyles-line-height-base) + spacing('base');
  }
}

@include media-query(large) {
  .c-results-card {
    position: relative;
  }

  .c-results-card::after {
    content: '';
    display: block;
    position: absolute;
    top: 2em;
    left: calc(100% + #{spacing('base')});
    width: 0;
    height: calc(100% - 4em);
    border: 1px solid palette('background');
  }

  .c-results-card--last::after {
    border: none;
  }
}
