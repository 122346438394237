.c-activity-ring {
  position: relative;
}

.c-activity-ring__ring {
  width: 100%;
  height: auto;
  transform: rotate(90deg);
  transform-origin: 50%;
}

.c-activty-ring__progress {
  animation: draw-activity-line 2s;
  stroke-width: 7.5;
  stroke-linecap: round;
  stroke: palette('question-correct');
  fill: none;
}

.c-activty-ring__background {
  fill: palette('question-incorrect');
}

.c-activity-ring__summary {
  z-index: 1;
  top: 45%;
  max-width: 75%;
}

.c-activity-ring__large {
  display: block;
  font-size: 2.5rem;
  line-height: 1;
}

@keyframes draw-activity-line {
  from {
    stroke-dashoffset: 243.16; // magic number, based on sizes in SVG
  }
}
