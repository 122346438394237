.o-button--pale {
  border: none;
  background: none;
  color: palette('grey');
  text-shadow: none;

  &:visited {
    color: palette('grey');
  }

  &:hover,
  &:focus {
    background: none;
    color: palette('grey', 'dark');
  }
}
