.o-button--question {
  width: 100%;
  height: 100%;
  margin-top: -2px;
  padding: 0;
  overflow: hidden;
  border: 0;
  border-radius: 0;

  .c-progress-list__item:first-child & {
    border-radius: $bitstyles-border-radius-round 0 0 $bitstyles-border-radius-round;
  }

  .c-progress-list__item:last-child & {
    border-radius: 0 $bitstyles-border-radius-round $bitstyles-border-radius-round 0;
  }
}

.o-button--question--active {
  &::after {
    @include absolute-center;

    content: '';
    width: spacing('small');
    height: spacing('small');
    border-radius: 50%;
    background-color: white;
    pointer-events: none;
  }
}

.o-button--question-incomplete {
  &,
  &:visited {
    background: palette('question-incomplete');
  }

  &:hover,
  &:focus {
    background: palette('question-incomplete', 'dark');
  }
}

.o-button--question-visited {
  &,
  &:visited {
    background: palette('question-visited');
  }

  &:hover,
  &:focus {
    background: palette('question-visited', 'dark');
  }
}

.o-button--question-answered {
  &,
  &:visited {
    background: palette('question-answered');
  }

  &:hover,
  &:focus {
    background: palette('question-answered', 'dark');
  }
}

.o-button--question-not-visited {
  &,
  &:visited {
    background: palette('question-not-visited');
  }

  &:hover,
  &:focus {
    background: palette('question-not-visited', 'dark');
  }
}

.o-button--question-correct {
  &,
  &:visited {
    background: palette('question-correct');
  }

  &:hover,
  &:focus {
    background: palette('question-correct', 'dark');
  }
}

.o-button--question-incorrect {
  &,
  &:visited {
    background: palette('question-incorrect');
  }

  &:hover,
  &:focus {
    background: palette('question-incorrect', 'dark');
  }
}

.o-button--question-info {
  &::before {
    @include absolute-center;

    content: '';
    width: 100%;
    height: 100%;
    border-radius: 0;

    // this background image is stripes with transparent background
    // generated with: http://www.patternify.com/
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAD0lEQVQImWNgQAX/yeAAAIHCA/0RE2WAAAAAAElFTkSuQmCC") repeat;
    pointer-events: none;
  }
}
