.public-DraftEditor-content {
  .public-DraftStyleDefault-ul {
    margin-left: spacing('large');
    list-style-type: disc;
  }

  .public-DraftStyleDefault-ol {
    margin-left: spacing('large');
    list-style-type: upper-roman;
  }
}
