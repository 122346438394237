$tile-height: 180px;

/* stylelint-disable selector-max-type */
.c-performance-tile {
  width: 100%;
  padding: spacing('base');
  border: 1.5px solid palette('background', 'dark');
  border-radius: spacing('small');
  text-align: center;

  h3 {
    margin-bottom: spacing('small');
  }
}

.c-performance-tile__result {
  max-height: $tile-height;

  .c-activity-ring__ring {
    height: $tile-height;
  }

  h1 {
    margin: 0;
    font-family: ProximaNova, sans-serif;
    font-size: 2rem;
    font-weight: 700;
  }
}
/* stylelint-enable selector-max-type */
