$bitstyles-font-family-header: sans-serif;
$bitstyles-font-family-header-loaded: 'EuclidFlex', sans-serif;
$bitstyles-font-family-body: sans-serif;
$bitstyles-font-family-body-loaded: 'ProximaNova', sans-serif;

// $bitstyles-font-weight-light: 300;
// $bitstyles-font-weight-normal: 400;
// $bitstyles-font-weight-bold: 700;
//
$bitstyles-font-size-base-small: 15px;
$bitstyles-font-size-base: 17px;

//
// $bitstyles-line-height-base: 1.5;

$bitstyles-font-sizes: (
  'base': (
    'html': $bitstyles-font-size-base-small,
    'h0': 32px,
    'h1': 24px,
    'h2': 20px,
    'h3': 18px,
    'h4': 14px,
    'h5': 14px,
    'h6': 12px
  ),
  'medium': (
    'html': $bitstyles-font-size-base,
    'h0': 32px,
    'h1': 24px,
    'h2': 20px,
    'h3': 18px,
    'h4': 16px,
    'h5': 14px,
    'h6': 12px
  )
);
