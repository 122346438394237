.c-connection-warning__container {
  position: fixed;
  right: spacing('small');
  bottom: spacing('small');
}

.c-connection-warning {
  padding: spacing('small');
  background: palette('background', 'dark');
  color: $bitstyles-color-white;
}
