@include media-query(small-and-medium) {
  .o-nav-link--course {
    &,
    &:visited {
      display: inline-flex;
      width: auto;
      max-width: 100%;
      padding: spacing('small');
      border: 1.5px solid $bitstyles-color-white;
      border-radius: $bitstyles-border-radius-round;
      background: transparent;
      box-shadow: none;
      color: $bitstyles-color-white;
      font-size: px-to-rem(map-get(map-get($bitstyles-font-sizes, 'base'), 'h5'));
      line-height: 1;
      text-transform: uppercase;
    }

    .o-nav-link__icon {
      color: $bitstyles-color-white;
    }

    &:hover,
    &:active,
    &.is-active {
      background: $bitstyles-color-white;
      color: var(--brand-color-primary);

      .o-nav-link__icon {
        color: var(--brand-color-primary);
      }
    }
  }
}

@include media-query(large) {
  .o-nav-link--course {
    &.is-active {
      display: none;
    }
  }
}
