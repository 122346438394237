.o-center-children-wrapper {
  display: flex;
}

.o-center-children {
  display: flex;
  flex: 1 100%;
  flex-direction: column;
  justify-content: center;
}
