/* stylelint-disable selector-max-type */
.c-product-grid {
  display: grid;
  grid-template-columns: 5fr 1rem 6fr 4fr;
  width: 100%;
  gap: 0.5rem;

  @include media-query('small-only') {
    grid-template-columns: 6fr 1rem 4fr 4fr;
  }
}

.c-product-grid__name {
  grid-column: 1;
  align-self: center;
}

.c-product-grid__status {
  place-self: center;
}

.c-product-grid__remaining-days {
  grid-column: 3;
  align-self: center;
  font-size: 80%;
}

.c-product-grid__shop {
  grid-column: 4;
  place-self: center end;
  width: 80%;

  @include media-query('small-only') {
    width: 100%;
  }

  a {
    display: block;
  }
}
/* stylelint-enable selector-max-type */
