.t-no-margin {
  margin: 0;
}

@include media-query(small-only) {
  .t-no-margin\@small-only {
    margin: 0;
  }
}

@include media-query(medium) {
  .t-no-margin\@medium {
    margin: 0;
  }
}
