.o-button {
  @include text-shadow;

  display: inline-flex;
  z-index: 0;
  align-items: center;
  justify-content: center;
  border-color: $bitstyles-button-background-color;
  text-decoration: none;

  &:visited {
    background: $bitstyles-button-background-color;
    color: $bitstyles-button-color;
  }

  &:hover,
  &:focus {
    border-color: $bitstyles-button-background-color-hover;
    background: $bitstyles-button-background-color-hover;
    color: $bitstyles-button-color-hover;
    text-decoration: none;
  }

  &[disabled] {
    /* stylelint-disable declaration-no-important */
    border-color: $bitstyles-button-border-color-disabled !important;
    background: $bitstyles-button-background-color-disabled !important;
    color: $bitstyles-button-color-disabled !important;
    text-shadow: none;
    cursor: default;
    /* stylelint-enable declaration-no-important */
  }
}

.o-button__label {
  z-index: 2;
  vertical-align: baseline;
}

.o-button__label--flex {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.o-button__answer-label {
  flex: 1;
}

.o-button__badge {
  @include vertical-center;

  position: absolute;
  z-index: 1;
  right: -1px;
  transition: $bitstyles-button-transition;

  .o-button[disabled] & {
    background-color: $bitstyles-button-background-color-disabled;
  }
}

@include media-query(small-only) {
  .o-button__badge {
    background: transparent !important; // stylelint-disable-line declaration-no-important
  }
}

.o-button__notification {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.o-button__badge + .o-button__label {
  margin-right: spacing('base');
}
