/* stylelint-disable declaration-no-important */
.t-text-align--center {
  text-align: center !important;
}

.t-text-align--left {
  text-align: left !important;
}

.t-text-align--right {
  text-align: right !important;
}
