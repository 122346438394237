.t-gap {
  gap: spacing('base');
}

.t-gap-s {
  gap: spacing('small');
}

.t-gap-xs {
  gap: spacing('x-small');
}
