$focus-test-col-count: 40;
$focus-test-col-width: 1.2575em;

.c-focus-test {
  position: relative;
  max-width: 100%;
  margin-bottom: spacing('x-small');
  padding: spacing('small') 0;
}

.c-focus-test__row {
  display: grid;
  grid-template-columns: repeat(#{$focus-test-col-count}, 1fr);

  > * {
    line-height: 1;
  }
}

.c-focus-test__grid {
  @include overflow;

  position: relative;
  padding: spacing('x-small') spacing('x-small') spacing('x-small') 0;
  border: 1px solid;
  counter-reset: focus-test-rows;

  .c-focus-test__row {
    grid-template-columns: 1fr repeat($focus-test-col-count, $focus-test-col-width);
    counter-increment: focus-test-rows;
  }

  .c-focus-test__row::before {
    content: counter(focus-test-rows);
    position: sticky;
    z-index: 1;
    left: 0;
    width: calc(2ch + #{2 * spacing('x-small')});
    padding: 0 spacing('x-small');
    border-right: 1px solid palette('text', 'light');
    background: palette('background-secondary');
    text-align: right;
  }
}

.c-focus-test--one-row {
  @include overflow;

  border: 1px solid palette('grey');
  border-radius: spacing('x-small');

  .c-focus-test__rows {
    user-select: none;
  }

  .c-focus-test__row {
    display: flex;
  }
}

.c-focus-test__rows-pdf {
  .o-button--glyph {
    border: 0;
  }
}

.c-focus-test__rows-hidden {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
}
