@include media-query(small-and-medium) {
  .c-course-list {
    > * {
      margin-bottom: spacing('small');
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
