.l-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.l-main__body {
  flex-grow: 1;
}

.l-main__footer {
  padding-top: spacing('large');
  padding-bottom: spacing('base');
  font-size: 0.8rem;
}
