.o-nav-link--topbar {
  &,
  &:visited {
    background-color: transparent;
    color: palette('background-secondary');
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: var(--brand-color-secondary);
  }

  .t-background-color--secondary &:hover,
  .t-background-color--secondary &:focus {
    color: var(--brand-color-primary);
  }

  &.is-active {
    color: var(--brand-color-secondary);
  }
}
