$border-color: $bitstyles-color-black;
$border-width: 4px;

/* stylelint-disable declaration-no-important */

/* We need to use `!important` a lot in order to override the [disabled] styles (these buttons are used unlike 'normal' buttons as they also display state while disabled) */
.o-button--glyph {
  flex-grow: 1;
  padding: 10%;
  border-width: 1px;
  border-radius: 25%;
  border-color: transparent;
  background: transparent;
  color: $border-color;
  line-height: 0;

  &[disabled] {
    border-color: transparent !important;
    background: transparent !important;
    color: $border-color !important;
  }

  &:hover,
  &:focus {
    border-color: var(--button-background);
    background-color: var(--button-background);
  }

  &.is-selected {
    border-color: $border-color !important;

    &::before,
    &::after {
      @include absolute-center;

      content: '';
      display: block;
      width: 142%; // approx diagonal of a square
      height: $border-width;
      transform: translate(-50%, -50%) rotate(-45deg);
      background: $border-color;
      pointer-events: none;
    }

    &::after {
      height: 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.is-correct {
    border-color: palette('question-correct') !important;
    background-color: palette('question-correct') !important;
  }

  &.is-incorrect {
    border-color: palette('question-incorrect') !important;
    background-color: palette('question-incorrect') !important;
  }
}
/* stylelint-enable declaration-no-important */

.o-button--glyph-large {
  min-width: 4rem;
  padding: spacing('x-small');
  border-width: $border-width;
  border-radius: spacing('small');

  &.is-selected {
    &::after {
      height: $border-width;
    }
  }
}
