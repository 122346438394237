.c-answer-list-wrapper {
  position: relative;
  padding-left: spacing('base');

  &::after {
    @include absolute-cover;

    content: '';
    display: block;
    right: auto;
    width: spacing('x-small');
    border-radius: $bitstyles-border-radius-round;
    background: var(--chapter-color, var(--brand-color-primary));
  }
}

.c-answer-list {
  @include overflow;
  @include list-shadows;

  max-width: 100%;
  max-height: none;
  overflow: auto;
  counter-reset: answer-counter;
}

.c-answer-list__item {
  margin-bottom: spacing('x-small');

  &:last-child {
    margin-bottom: 0;
  }
}

.c-answer-list__answer {
  /* stylelint-disable selector-max-type */
  figure,
  .c-tiptap-content-viewer img,
  .c-tiptap-content-viewer table { margin-bottom: 0; }

  /* stylelint-enable selector-max-type */

  &::before {
    content: "(" counter(answer-counter, upper-alpha) ")";
    display: block;
    position: absolute;
    top: spacing('small');
    left: spacing('base');
    counter-increment: answer-counter;
  }
}

.c-answer-list__answer--horizontal {
  &::before {
    position: static;
    text-align: center;
  }
}

.c-answer-list--horizontal {
  display: flex;

  .c-answer-list__item {
    flex: 1 0 auto;
    margin-right: spacing('small');
    margin-bottom: 1px; // fixes rendering/rounding errors that result in bottom margin being clipped off.
  }
}

@include media-query(medium) {
  .c-answer-list-wrapper {
    padding-left: spacing('large');

    &::after {
      width: spacing('small');
    }
  }
}
