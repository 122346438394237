.o-select {
  @include fixed-ratio(1, 1);
  @include text-shadow;

  z-index: 0;
  width: 100%;
  padding: 0;
  border: $bitstyles-button-border var(--brand-color-primary);
  border-radius: 0;
  background: transparent;
  text-align: right;
  text-decoration: none;
}
