.c-chapter-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: spacing('base') - spacing('small');
}

.c-chapter-list__item {
  margin-bottom: spacing('small');
  margin-left: spacing('small');
}

@include media-query(medium) {
  .c-chapter-list {
    flex-direction: column;
  }

  .c-chapter-list__item {
    margin: 0;
    margin-bottom: spacing('base');
  }
}
