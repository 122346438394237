.o-badged-icon {
  position: relative;
  text-shadow: none;
}

.o-badged-icon__badge {
  @include absolute-center;

  z-index: 1;
  transform: translate(-50%, -75%);
  font-size: 2rem;
  font-weight: $bitstyles-font-weight-bold;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-text-stroke: 1px $bitstyles-color-white;
}

.o-badged-icon__icon {
  display: block;
}

.o-badged-icon--small {
  .o-badged-icon__badge {
    font-size: 0.7rem;
    font-weight: $bitstyles-font-weight-normal;
  }
}

.o-badged-icon--large {
  justify-content: center;
  padding: 0 spacing('large') spacing('base');

  .o-badged-icon__icon {
    display: block;
    width: 100%;
    max-width: 7rem;
    margin: 0 auto;
  }

  .o-badged-icon__badge {
    top: 37%;
    font-size: 4rem;
    -webkit-text-stroke: 2px $bitstyles-color-white;
  }
}

.o-badged-icon__badge--corner {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  padding: spacing('x-small') (spacing('x-small') * 1.25);
  transform: none;
  border-radius: $bitstyles-border-radius-round;
  background: palette('badge-score');
}

.o-badged-icon__badge--trophy {
  padding: 0.25em;

  .o-badged-icon__badge {
    font-weight: $bitstyles-font-weight-bold;
  }
}

.o-badged-icon__trophy-label {
  transform: translate(-50%, -70%);
  color: palette('badge-score');
  letter-spacing: -0.1em;
  -webkit-text-stroke: 0;
}
